<template>
  <div>
    <div class="input_info">
      <p class="input_text">用户名字：</p>
      <el-input
        v-model="requestParams.user"
        placeholder="请输入用户名字"
        size="small"
        clearable
      />
    </div>

    <div style="margin: 0 0 10px 10px">
      <el-row>
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          @click="dialogVisible = true"
          >添加</el-button
        >
      </el-row>
    </div>

    <div class="add_dialog">
      <el-dialog
        :title="`${form.id ? '编辑' : '添加'}管理员`"
        :visible.sync="dialogVisible"
        width="600px"
        @close="handleDialogClose"
        :before-close="handleClose"
        center
      >
        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">账号名：</p>
          <div class="addAdmin_select">
            <el-input
              v-model="form.user"
              placeholder="请输入账号名"
              clearable
            />
          </div>
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">真实用户名：</p>
          <div class="addAdmin_select">
            <el-input
              v-model="form.user_name"
              placeholder="请输入真实用户名"
              clearable
            />
          </div>
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">密码：</p>
          <div class="addAdmin_select">
            <el-input
              v-model="form.password"
              placeholder="请输入密码"
              clearable
            />
          </div>
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">用户组ID：</p>
          <div class="addAdmin_select">
            <el-input
              v-model="form.group_id"
              placeholder="请输入用户组ID"
              clearable
            />
          </div>
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button
            v-loading="isLoading.table"
            type="primary"
            @click="handleDialogConfirm"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>

    <div class="table_style">
      <el-table
        ref="multipleTable"
        v-loading="isLoading.table"
        header-align="center"
        border
        class="main-table"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="id" label="ID" align="center" />
        <el-table-column prop="user" label="账号名" align="center" />
        <el-table-column prop="user_name" label="真实用户名" align="center" />
        <el-table-column prop="password" label="密码" align="center" />
        <el-table-column prop="ps_code" label="密码扩充码" align="center" />
        <el-table-column prop="group_id" label="用户组ID" align="center" />
        <el-table-column prop="created_at" label="添加时间" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.created_at
                ? new Date(row.created_at * 1000).toLocaleString("zh-Hans-CN")
                : "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updated_at" label="更新时间" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.updated_at
                ? new Date(row.updated_at * 1000).toLocaleString("zh-Hans-CN")
                : "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="operation"
          label="操作"
          align="center"
          width="300"
        >
          <template slot-scope="{ row, $index }">
            <div>
              <el-button
                type="warning"
                size="small"
                plain
                icon="el-icon-edit-outline"
                style="margin-right: 10px"
                @click.stop="handleEditAuth(row)"
                >编辑</el-button
              >
              <el-popover trigger="click" placement="top">
                <p>
                  你确认要删除这位用户吗? <strong>{{ row.username }}</strong>
                </p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="hidePopover()"
                    >取消</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="handleDeleteAuth(row.id, $index)"
                    >确认</el-button
                  >
                </div>
                <el-button
                  slot="reference"
                  plain
                  icon="el-icon-delete"
                  type="danger"
                  size="small"
                  @click.stop
                  >删除</el-button
                >
              </el-popover>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div ref="footer" class="footer">
      <el-pagination
        :key="reloadPaginate"
        :current-page="pagination.page"
        :page-size="pagination.pageSize"
        :page-sizes="[10, 20, 50, 100, 200]"
        layout="total,sizes, prev, pager, next, jumper"
        :total="pagination.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      requestParams: {
        user: "",
      },
      form: {
        user: "",
        user_name: "",
        password: "",
        group_id: "",
      },
      tableData: [],
      isLoading: {
        table: false,
      },
      reloadPaginate: 0,
      pagination: {
        total: 0,
        page: 1,
        pageSize: 10,
      },
      dialogVisible: false,
    };
  },
  watch: {
    "requestParams.user"() {
      this.getTableData();
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    // 分页跳转
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.getTableData();
    },
    //弹窗关闭
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done(_);
        })
        .catch((_) => {
          _;
        });
    },
    //获取到管理员列表
    getTableData() {
      this.tableData = [];
      this.pagination.total = 0;
      this.$axios
        .get("/a1/admin", {
          params: {
            user: this.requestParams.user,
            pageSize: this.pagination.pageSize,
            page: this.pagination.page,
          },
        })
        .then((res) => {
          const { data } = res.data;
          this.pagination.total = data.total;
          this.tableData = data.data;
          this.reloadPaginate++;
        });
    },
    // 深拷贝
    handleEditAuth(row) {
      const formData = JSON.parse(JSON.stringify(row));
      this.form.user = formData.user;
      this.form.user_name = formData.user_name;
      this.form.password = formData.password;
      this.form.group_id = formData.group_id;
      this.form.id = formData.id;
      this.dialogVisible = true;
    },
    // 编辑跟新增
    handleDialogConfirm() {
      this.isLoading.table = true;
      if (this.form.id) {
        this.$axios
          .put(
            `/a1/admin/${this.form.id}`,
            Object.assign({}, this.form, {
              group_id: parseInt(this.form.group_id),
            })
          )
          .then(() => {
            this.$message.success("修改成功");
            this.getTableData();
            this.dialogVisible = false;
          })
          .finally(() => {
            this.isLoading.table = false;
          });
        return;
      }
      this.$axios
        .post(
          "/a1/admin",
          Object.assign({}, this.form, {
            group_id: parseInt(this.form.group_id),
          })
        )
        .then(() => {
          this.$message.success("新增成功");
          this.getTableData();
          this.dialogVisible = false;
        })
        .finally(() => {
          this.isLoading.table = false;
        });
    },
    // 清空表格数据
    handleDialogClose() {
      this.form = {
        user: "",
        user_name: "",
        password: "",
        group_id: "",
      };
    },
    // 删除
    handleDeleteAuth(id, index) {
      this.isLoading.table = true;
      this.$axios
        .delete(`/a1/admin/${id}`)
        .then(() => {
          this.tableData.splice(index, 1);
          this.$notify({
            title: "成功",
            message: "删除管理员",
            type: "success",
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "失败",
            message: "删除管理员",
          });
        })
        .finally(() => {
          this.hidePopover();
          this.isLoading.table = false;
        });
    },
    hidePopover() {
      this.$refs.footer.click();
    },
  },
};
</script>
<style lang="scss" scoped>
.input_info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 300px;
  margin: 10px 0 10px 10px;
}
.input_text {
  width: 120px;
  color: #889aa4;
}

.footer {
  margin: 100px;
  text-align: center;
}
.addAdmin {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;

  &_CharacterSet {
    font-size: 15px;
    width: 120px;
    text-align: right;
  }

  &_select {
    flex: 1;
  }
}
.table_style {
  margin-left: 10px;
}
</style>
